import * as Yup from "yup"
import {
  getCustomValidation,
  getDefaultValidation,
} from "../../../../services/validations"

export const formSchema = ({ fields, t }) => {
  let validationSchema = Yup.object()

  for (let field of fields) {
    let fieldValidation = getDefaultValidation({ field, t })
    fieldValidation = getCustomValidation({ field, fieldValidation })

    validationSchema = validationSchema.concat(
      Yup.object().shape({
        [field.name]: fieldValidation,
      })
    )
  }

  const validationSchemaFields = Object.keys(validationSchema.fields)
  const hospitalOtherNames = {
    hospitalName: "otherHospital",
    hospitalClinic: "otherHospital",
    secondaryHospitalClinic: "secondaryOtherHospital",
  }

  for (const sourceName in hospitalOtherNames) {
    if (!hospitalOtherNames.hasOwnProperty(sourceName)) continue

    if (!validationSchemaFields.includes(sourceName)) continue

    validationSchema = validationSchema.concat(
      Yup.object().shape({
        [hospitalOtherNames[sourceName]]: Yup.string().when(sourceName, {
          is: (hospitalName) => {
            return hospitalName.value === "Other"
          },
          then: (schema) => schema.trim().required("This field is required"),
          otherwise: (schema) => schema,
        }),
      })
    )
  }

  return validationSchema
}
